<template>
  <div class="px-3 flex">
    <div class="w-full min-h-screen overflow-y-hidden overflow-x-auto pb-5">
      <div
        class="h-14 flex items-center justify-between border-b border-black-200"
      >
        <div>
          <button
            class="
            w-max
              border border-white-100
              bg-blue-500
              px-2
              rounded
              text-xs text-white-100
              py-2
              hover:pointer hover:bg-blue-600
            "
            @click="AllTasks"
          >
            <i class="fas fa-check-circle text-xs text-white-100 mr-1"></i> All
            tasks
          </button>
        </div>
        <form class="flex justify-end items-center py-2">
          <multiselect
            class="
              border-0
              w-44
              mr-4
              rounded-lg
              text-black-50 text-xs
              mb-0
              placeholder-mb-0
              select
              statusSelect
            "
            :options="statusOptions"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :allow-empty="false"
            :searchable="true"
            :show-labels="false"
            placeholder="Select Status"
            name="taskStatus"
            id="status"
            track-by="id"
            label="name"
            v-model="selectedSatus"
            @select="saveStatus"
          >
          </multiselect>
          <multiselect
            class="
              border-0
              w-44
              mr-4
              rounded-lg
              text-gray-900 text-xs
              mb-0
              placeholder-mb-0
              select
              statusSelect
            "
            :options="TaskPriority"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :allow-empty="false"
            :searchable="true"
            :show-labels="false"
            placeholder="Select Priority"
            name="taskStatus"
            id="priority"
            track-by="id"
            label="slug"
            v-model="selectedPriority"
            @select="savePriority"
          >
          </multiselect>
          <button
            type="submit"
            class="
              bg-blue-500
              h-8
              text-white-100
              px-4
              py-0
              rounded
              hover:bg-blue-600 hover:font-bold
            "
            @click.prevent="filterTask"
          >
            Filter
          </button>
        </form>
      </div>

      <div>
        <table
          class="
            w-full
            pt-64
            text-sm text-left text-gray-500
            dark:text-gray-400
          "
        >
          <thead
            class="
              text-xs
              sticky
              top-0
              bg-white-100
              border-b border-black-200
              text-black-50
            "
          >
            <tr class="py-8">
              <th
                scope="col"
                class="
                  px-2
                  py-2.5
                  font-normal
                  border-r border-black-200
                  text-xs
                  lg:w-1/3
                  overflow-ellipsis overflow-clip
                "
              >
                Task name
              </th>
              <th
                scope="col"
                class="
                  px-2
                  py-2
                  font-normal
                  border-r border-black-200
                  text-xs
                  w-1/7
                "
              >
                Asignee
              </th>
              <th
                scope="col"
                class="
                  px-2
                  py-2
                  font-normal
                  border-r border-black-200
                  text-xs
                  w-1/5
                "
              >
                Due Date
              </th>
              <th
                scope="col"
                class="
                  px-2
                  py-2
                  font-normal
                  border-r border-black-200
                  text-xs
                  w-1/7
                "
              >
                Priority
              </th>
              <th
                scope="col"
                class="
                  px-2
                  py-2
                  flex
                  justify-between
                  font-normal
                  border-r border-black-200
                  text-xs
                  w-1/7
                "
              >
                Status
                <span
                  ><i
                    class="fas fa-plus text-xs"
                    @click="callTaskStatusModal"
                  ></i
                ></span>
              </th>
              <th
                scope="col"
                class="
                  px-2
                  py-2
                  font-normal
                  border-r border-black-200
                  text-xs
                  w-1/5
                "
              >
                Time
              </th>
            </tr>
          </thead>
          <tbody class="listBody" v-if="myAbilities.can('get_task', 'pms')">
            <tr
              class="
                newList
                bg-white
                border-b border-black-200
                text-black-100
                dark:bg-gray-800 dark:border-gray-700
                hover:bg-gray-100
              "
              v-for="task in Project.task"
              :key="task.id"
            >
              <th
                scope="row"
                class="
                  flex
                  justify-between
                  px-4
                  py-2
                  font-normal
                  whitespace-nowrap
                  border-r border-black-200
                  overflow-ellipsis
                  md:overflow-clip
                "
              >
                <input
                  class="text-black-50 lg:w-full mr-10 placeholder-text-black-50"
                  contenteditable="true"
                  placeholder="Assign task"
                  :value="task.name"
                  @focusout="updataTaskName($event.target.value, task.id)"
                />
                <button
                  class="text-xs text-white-100 bg-black-50 rounded-md px-2"
                  @click.stop="NewDetail(task)"
                  data-tooltip-target="detail-tooltip"
                  data-tooltip-placement="bottom"
                >
                  Detail
                </button>
                <div
                  id="detail-tooltip"
                  role="tooltip"
                  class="
                    inline-block
                    absolute
                    invisible
                    z-10
                    py-1
                    px-2
                    text-xs text-black-100
                    bg-white-100
                    rounded
                    border border-yellow-200
                    shadow-sm
                    opacity-0
                    tooltip
                  "
                >
                  View Details
                </div>
              </th>

              <!-- Select for user -->
              <td class="border-r border-black-200 relative">
                <multiselect
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                    select
                    .mx-datepicker
                  "
                  v-if="myAbilities.can('update_task', 'pms')"
                  :options="Project.member"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Select"
                  name="userSelect"
                  id="user"
                  track-by="id"
                  label="name"
                  v-model="task.user"
                  @input="assignTaskTo(task.id, task.name)"
                  @select="saveAssignToValue"
                  :disabled="!Project.is_add_task"
                >
                </multiselect>
                <!-- If user does not have permission to update task -->
                <multiselect
                  v-else
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                    select
                  "
                  disabled
                  :options="Project.member"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Select"
                  name="userSelect"
                  id="user"
                  track-by="id"
                  label="name"
                  v-model="task.user"
                  @input="assignTaskTo(task.id, task.name)"
                  @select="saveAssignToValue"
                >
                </multiselect>
              </td>
              <!-- User Select End -->

              <!-- date range picker -->
              <td class="px-3 border-r border-black-200">
                <date-picker
                  v-if="myAbilities.can('update_task', 'pms')"
                  class="text-xs datepicker w-full pb-0 picker"
                  valueType="YYYY-MM-DD"
                  type="date"
                  placeholder="Deadline"
                  @close="updateTaskDeadline(task.id, task.name)"
                  @change="saveDeadlineValue"
                  v-model="task.deadline"
                >
                </date-picker>

                <date-picker
                  v-else
                  class="text-xs datepicker w-full pb-0 picker"
                  valueType="YYYY-MM-DD"
                  type="date"
                  placeholder="Deadline"
                  disabled
                  v-model="task.deadline"
                >
                </date-picker>
              </td>
              <!-- date range picker end -->

              <!-- Select for priority -->
              <td class="border-r border-black-200">
                <multiselect
                  v-if="myAbilities.can('update_task', 'pms')"
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                    select
                  "
                  :options="TaskPriority"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Select "
                  name="priority"
                  id="priority"
                  track-by="id"
                  label="value"
                  @input="updateTaskPriority(task.id, task.name)"
                  @select="savePriorityValue"
                  v-model="task.task_priority"
                >
                </multiselect>

                <multiselect
                  v-else
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                    select
                  "
                  disabled
                  :options="TaskPriority"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Select "
                  name="priority"
                  id="priority"
                  track-by="id"
                  label="value"
                  @input="updateTaskPriority(task.id, task.name)"
                  @select="savePriorityValue"
                  v-model="task.task_priority"
                >
                </multiselect>
              </td>
              <!-- priority select end -->

              <!-- Select for Status -->
              <td class="border-r border-black-200">
                <multiselect
                  v-if="myAbilities.can('update_task', 'pms')"
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                    select
                  "
                  :options="Project.task_status"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Select"
                  name="statusSelect"
                  id="status"
                  track-by="id"
                  label="name"
                  @input="
                    updateTaskStatus(task.id, task.name, task.task_status)
                  "
                  @select="saveStatusValue"
                  v-model="task.task_status"
                >
                </multiselect>

                <multiselect
                  v-else
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                    select
                  "
                  disabled
                  :options="Project.task_status"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Select"
                  name="statusSelect"
                  id="status"
                  track-by="id"
                  label="name"
                  @input="
                    updateTaskStatus(task.id, task.name, task.task_status)
                  "
                  @select="saveStatusValue"
                  v-model="task.task_status"
                >
                </multiselect>
              </td>
              <!-- status end -->
              <td class="px-3 border-r border-black-200">
                <date-picker
                  v-if="myAbilities.can('update_task', 'pms')"
                  class="text-xs datepicker border-0 w-full pb-0 picker"
                  valueType="HH:mm:ss"
                  type="time"
                  placeholder="Estimated Duration"
                  @close="updateTaskDuration(task.id, task.name)"
                  @change="saveDurationValue"
                  v-model="task.estimated_duration"
                >
                </date-picker>

                <date-picker
                  v-else
                  disabled
                  class="text-xs datepicker border-0 w-full pb-0 picker"
                  valueType="HH:mm:ss"
                  type="time"
                  placeholder="Estimated Duration"
                  @close="updateTaskDuration(task.id, task.name)"
                  @change="saveDurationValue"
                  v-model="task.estimated_duration"
                >
                </date-picker>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            No permission to show Tasks
          </tbody>
          <!-- New Task -->
          <tr
            class="
              bg-white
              border-b border-black-200
              w-full
              text-black-100
              dark:bg-gray-800 dark:border-gray-700
              hover:bg-gray-100
              hidden
              newTask
            "
            id="newTask"
            v-if="myAbilities.can('store_task', 'pms')"
          >
            <th
              scope="row"
              class="font-normal whitespace-nowrap border-r border-black-200"
            >
              <input
                type="name"
                id="name"
                class="
                  bg-white-100
                  task-inp
                  border border-gray-300
                  text-gray-900 text-sm
                  rounded-lg
                  focus:ring-transparent
                  block
                  w-full
                  p-2.5
                  dark:bg-gray-700
                  dark:border-gray-600
                  dark:placeholder-gray-400
                  dark:text-white
                  dark:focus:ring-blue-500
                  dark:focus:border-blue-500
                  px-4
                "
                v-model="newTask.name"
                v-on:keyup.enter="saveTask"
                @focusout="addNewTask"
                placeholder="Add task here ..."
                required
              />
            </th>
            <td class="border-r border-black-200">
              <multiselect
                class="
                  text-xs
                  w-full
                  border-0
                  mb-0
                  placeholder-mb-0
                  text-black-50
                  select
                "
                :options="Project.member"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :allow-empty="false"
                :searchable="true"
                placeholder="Select"
                :show-labels="false"
                name="userSelect"
                id="editUser"
                track-by="id"
                label="name"
                v-model="newTask.assignTo"
              >
              </multiselect>
            </td>
            <td class="px-3 border-r border-black-200 truncate">
              <date-picker
                class="text-xs outline-none w-full pb-0 picker"
                valueType="format"
                type="date"
                placeholder="Task Deadline"
                v-model="newTask.deadline"
              >
              </date-picker>
            </td>
            <!-- Select for priority -->
            <td class="border-r">
              <multiselect
                class="
                  text-xs
                  w-full
                  border-0
                  mb-0
                  placeholder-mb-0
                  text-black-50
                  select
                "
                :options="TaskPriority"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :allow-empty="false"
                :searchable="true"
                placeholder="Select "
                :show-labels="false"
                name="priority"
                id="priority"
                track-by="id"
                label="value"
                v-model="newTask.priority"
              >
              </multiselect>
            </td>
            <!-- priority select end -->
            <!-- Select for Status -->
            <td class="border-r border-black-200">
              <multiselect
                class="
                  text-xs
                  w-full
                  border-0
                  mb-0
                  placeholder-mb-0
                  text-black-50
                  select
                "
                :options="Project.task_status"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :allow-empty="false"
                :searchable="true"
                placeholder="Select"
                name="status"
                id="status"
                :show-labels="false"
                track-by="id"
                label="name"
                v-model="newTask.status"
              >
              </multiselect>
            </td>
            <!-- status end -->
            <td class="px-3 border-r border-black-200">
              <date-picker
                class="text-xs datepicker border-0 w-full pb-0 picker"
                valueType="format"
                type="time"
                placeholder="Estimated time"
                v-model="newTask.estimatedDuration"
              >
              </date-picker>
            </td>
          </tr>
        </table>
        <!-- button to open add task field to add new task -->
        <button
          class="
            bg-white-100
            text-black-50 text-sm
            px-5
            py-2
            w-full
            min-w-xl
            text-left
            dark:bg-gray-800 dark:border-gray-700
            hover:bg-gray-100
          "
          @click="addTask"
          v-if="myAbilities.can('store_task', 'pms')"
        >
          Add Task ...
        </button>
      </div>
    </div>
    <!-- Task Detail Sidebar -->
    <div class="flex justify-end max-w-xl hidden fixed z-10 taskDetail right-0">
      <aside
        class="w-screen hidden border border-black-200 taskDetail"
        aria-label="Sidebar"
      >
        <div class="flex flex-col overflow-y-auto h-screen bg-white-100">
          <header
            class="
              header
              border-b border-black-200
              py-4
              px-2
              flex
              justify-end
              items-center
            "
          >
            <div class="flex items-center">
              <div class="mr-4">
                <i
                  v-if="myAbilities.can('store_task_documents', 'pms')"
                  class="fas fa-paperclip text-black-50"
                  @click="toggleDocModal"
                ></i>
              </div>
              <nav class="flex pr-4" aria-label="Breadcrumb">
                <ol class="inline-flex items-center space-x-1 md:space-x-3">
                  <li class="inline-flex items-center">
                    <router-link
                      :to="{
                        name: 'TaskDetail',
                        params: { reviewData: this.currentTaskReview },
                      }"
                      class="
                        inline-flex
                        pr-2
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                    >
                      Details
                    </router-link>
                    |
                  </li>
                  <li class="inline-flex items-center">
                    <router-link
                      :to="{
                        name: 'QaStatus',
                        params: {
                          qaData: currentTaskQa,
                        },
                      }"
                      class="
                        inline-flex
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                    >
                      Q/A
                    </router-link>
                  </li>
                  <li class="inline-flex items-center">
                    |
                    <router-link
                      :to="{
                        name: 'Review',
                        params: {
                          reviewData: this.currentTaskReview,
                        },
                      }"
                      class="
                        inline-flex
                        pl-2
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                      @click.native="$event.stopImmediatePropagation()"
                    >
                      Review
                    </router-link>
                  </li>
                </ol>
              </nav>
              <button class="ml-3" @click="hideDetail">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </header>
          <main class="flex-1 overflow-y-auto">
            <router-view
              :default="{ name: 'Detail' }"
              @update-task-name="updataTaskName"
              @update-task-desc="updataTaskDescription"
            >
            </router-view>
          </main>
          <!-- Comments -->
          <div class="py-1 px-5 space-x-2 rounded-b shadow-md">
            <div class="flex justify-end pt-16">
              <button
                class="p-2 bg-blue-500 text-white-100 font-bold rounded-lg"
                @click="addComment"
              >
                Comment
              </button>
            </div>
          </div>
        </div>
      </aside>
    </div>

    <!-- document Modal -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="taskDocModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Add Files
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="toggleDocModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <form class="space-y-6" @submit.prevent="addTaskDoc">
              <input
                class="
                  block
                  w-full
                  mb-5
                  text-sm text-gray-900
                  border border-gray-300
                  rounded-lg
                  cursor-pointer
                  bg-white-100
                  focus:outline-none
                "
                id="default_size"
                type="file"
                @change="mapFile"
              />
              <div class="flex items-center"></div>

              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "List",
  props: {
    project: Object,
    projectTasks: Array,
    admin: Boolean,
  },
  data() {
    return {
      addList: false,
      content: "",

      currentTaskQa: {},
      currentTaskReview: {},
      preTask: "This is my first task.",
      taskName: "currentAssignTmulti",
      statusOptions: [],
      tasks: [],
      newTask: {
        name: "",
        assignTo: null,
        priority: null,
        status: null,
        estimatedDuration: null,
        deadline: null,
      },
      currentStatus: null,
      currentPriority: null,
      currentDeadline: null,
      currentDuration: null,
      currentAssignTo: null,
      currentTaskId: null,
      doc: {
        file: null,
      },
      crntTask: [],
      taskStatusId: 0,
      taskPriorityId: 0,
      selectedPriority: "",
      selectedSatus: "",
      selectedPriorityId: 0,
      selectedStatusId: 0,
    };
  },
  methods: {
    ...mapActions([
      "fetchPriority",
      "storeTask",
      "updateTask",
      "fetchTask",
      "storeTaskComment",
      "storeTaskDocument",
      "updateTaskComment",
      "fetchProject",
      "getFilteredTasks",
    ]),
    ...mapMutations(["toggleLoading", "setTask", "setTaskId"]),

    // function on add task btn
    addTask() {
      $("#newTask").removeClass("hidden");
      $(".task-inp").focus();
      this.newTask.priority = this.TaskPriority.find(
        (el) => el.slug == "medium"
      );
      this.newTask.assignTo = JSON.parse(localStorage.getItem("user"));
      this.newTask.status = this.Project.task_status.find((el) => el.is_default)
        ? this.Project.task_status.find((el) => el.is_default)
        : this.Project.task_status[0];
    },

    // Event for save status value
    saveStatus(item) {
      this.selectedSatus = item;
      this.selectedStatusId = this.selectedSatus.id;
    },

    // Event for save priority value
    savePriority(item) {
      this.selectedPriority = item;
      this.selectedPriorityId = this.selectedPriority.id;
    },

    // filter task
    async filterTask() {
      const body = {
        projectId: this.Project.id,
        statusId: this.selectedStatusId,
        priorityId: this.selectedPriorityId,
      };
      this.toggleLoading(true);
      let res = await this.getFilteredTasks(body);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }

      this.toggleLoading(false);
    },

    // All Tasks
    async AllTasks() {
      this.selectedSatus = "";
      this.selectedPriority = "";
      (this.selectedStatusId = 0), (this.selectedPriorityId = 0);
      const body = {
        projectId: this.Project.id,
        statusId: this.selectedStatusId,
        priorityId: this.selectedPriorityId,
      };
      this.toggleLoading(true);

      let res = await this.getFilteredTasks(body);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    async NewDetail(task) {
      let id = task.id;
      this.crntTask = task;
      this.currentTaskId = task.id;

      this.$router
        .push({
          name: "TaskDetail",
          params: { taskId: this.currentTaskId },
        })
        .catch(() => {});
      this.toggleLoading(true);
      await this.fetchTask({ id });
      let sidebar = $(".taskDetail");
      if (sidebar.hasClass("hidden")) {
        sidebar.removeClass("hidden");
      }
      this.toggleLoading(false);
      this.QaFunction();
      this.currentReview();
    },

    // For current review task, Review status
    currentReview() {
      if (this.Task.task_review.length != 0) {
        let taskReview = this.Task.task_review;
        let currentTaskReview = {};
        taskReview.forEach((element) => {
          if (element.end_time == null) {
            currentTaskReview = element;
          }
        });
        this.currentTaskReview = currentTaskReview;
      }
    },

    // for current qa task, for qa status
    QaFunction() {
      if (this.Task.task_qa.length != 0) {
        let taskQa = this.Task.task_qa;
        let currentTaskQa = {};
        taskQa.forEach((element) => {
          if (element.end_time == null) {
            currentTaskQa = element;
          }
        });
        this.currentTaskQa = currentTaskQa;
      }
    },

    // close detail sidebar
    hideDetail() {
      let stateObj = { id: "22" };
      window.history.replaceState(
        stateObj,
        "List",
        `/project/${this.$route.params.id}`
      );
      $(".taskDetail").addClass("hidden");
    },

    // function for document modal
    toggleDocModal() {
      document.getElementById("taskDocModal").classList.toggle("hidden");
    },

    //task methods

    //------- add task -----------
    async addNewTask() {
      this.toggleLoading(true);
      if (this.Project.task_status.length == 0) {
        this.$toasted.error("No task status, Please add status first");
      } else {
        if (this.newTask.name.length > 0) {
          let res = await this.storeTask({
            name: this.newTask.name,
            priority: this.newTask.priority.id,
            status_id: this.newTask.status.id,
            project_id: this.Project.id,
            assign_to: this.newTask.assignTo.id,
          });
          this.newTask = {
            name: "",
            assignTo: null,
            priority: null,
            status: null,
            estimatedDuration: null,
            deadline: null,
          };
          $(".newTask").addClass("hidden");
          if (res.success) {
            this.$toasted.success(res.msg);
          } else {
            this.$toasted.error(res.msg);
          }
        }
      }

      this.toggleLoading(false);
    },

    //----- update task ----------
    //access selected status value
    saveStatusValue(item) {
      this.currentStatus = item;
    },
    //task status
    async updateTaskStatus(item, name) {
      this.toggleLoading(true);
      let res = await this.updateTask({
        id: item,
        name: name,
        status_id: this.currentStatus.id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    //access selected priority value
    savePriorityValue(item) {
      this.currentPriority = item;
    },
    //task priority
    async updateTaskPriority(id, name) {
      this.toggleLoading(true);
      let res = await this.updateTask({
        id: id,
        name: name,
        priority: this.currentPriority.id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    //access selected deadline value
    saveDeadlineValue(item) {
      this.currentDeadline = item;
    },
    //task deadline
    async updateTaskDeadline(id, name) {
      this.toggleLoading(true);
      let res = await this.updateTask({
        id: id,
        name: name,
        deadline: this.currentDeadline,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    //access selected duration value
    saveDurationValue(item) {
      this.currentDuration = item;
    },
    //task Duration
    async updateTaskDuration(id, name) {
      this.toggleLoading(true);
      let res = await this.updateTask({
        id: id,
        name: name,
        estimated_duration: this.currentDuration,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    //access selected assign to value
    saveAssignToValue(item) {
      this.currentAssignTo = item;
    },

    //task assign to
    async assignTaskTo(id, name) {
      if (this.Project.is_add_task) {
        this.toggleLoading(true);
        let res = await this.updateTask({
          id: id,
          name: name,
          assign_to: this.currentAssignTo.id,
        });
        if (res.success) {
          this.$toasted.success(res.msg);
        } else {
          this.$toasted.error(res.msg);
        }
        this.toggleLoading(false);
      } else {
        this.tasks = this.Project.task;
        this.$toasted.error("You dont have permission to assign task!");
      }
    },
    //update task name
    async updataTaskName(name, id) {
      this.toggleLoading(true);
      let res = await this.updateTask({
        id: id,
        name: name,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },
    //update task description
    async updataTaskDescription(descr, id, name) {
      this.toggleLoading(true);
      let res = await this.updateTask({
        id: id,
        name: name,
        description: descr,
      });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
    },
    //add task doc
    mapFile(e) {
      this.doc.file = e.target.files[0];
    },
    async addTaskDoc() {
      this.toggleLoading(true);
      let formdata = new FormData();
      formdata.append("file", this.doc.file);
      formdata.append("task_id", this.$route.params.id);
      let res = await this.storeTaskDocument(formdata);
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleDocModal();
      this.doc.file = "";
    },

    //------comment --------
    async addComment() {
      this.toggleLoading(true);
      let res = await this.storeTaskComment({
        description: this.comment.description,
        task_id: this.$route.params.id,
      });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.comment.description = "";
      } else {
        this.$toasted.error(res.msg);
      }
    },

    async updateComment() {
      this.toggleLoading(true);
      let res = await this.updateTaskComment(this.comment);
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.comment.description = "";
        this.comment.id = "";
      } else {
        this.$toasted.error(res.msg);
      }
    },
    //----- event bus methods ------
    //open task status add modal
    callTaskStatusModal() {
      this.$router.replace(`/projectOverview/${this.$route.params.id}`);
      this.$eventBus.$emit("callTaskStatusModal");
    },
  },
  computed: {
    ...mapGetters(["Project", "QaComments", "TaskPriority", "Task", "myAbilities"]),
  },
  async created() {
    await this.fetchPriority();
    this.tasks = this.Project.task;
    this.Project.task_status.find((el) => {
      this.statusOptions.push(el);
    });
  },
  async mounted() {
    // sidebar conditions on routes change
    let url = window.location.href;
    let segment = url.split("/").pop();
    if (segment == "taskDetail") {
      $(".taskDetail").removeClass("hidden");
    }
    if (segment == "review") {
      $(".taskDetail").removeClass("hidden");
    }
    if (segment == "qastatus") {
      $(".taskDetail").removeClass("hidden");
    }
    this.TaskPriority.length == 0 && (await this.fetchPriority());
    this.tasks = this.Project.task;

    //As an instance property
    this.$eventBus.$on("callMethodInChild", (id) => {
      this.NewDetail(id);
    });
    this.$eventBus.$on("addTask", async () => {
      this.newTask.priority = this.TaskPriority.find(
        (el) => el.slug == "medium"
      );
      this.newTask.assignTo = JSON.parse(localStorage.getItem("user"));
      this.newTask.status = this.Project.task_status.find((el) => el.is_default)
        ? this.Project.task_status.find((el) => el.is_default)
        : this.Project.task_status[0];
      await this.addNewTask();
    });
  },
};
</script>

<style scoped>
.multiselect {
  position: ab;
}
.datepicker {
  outline: 0px !important;
}
.datepicker > .mx-input-wrapper > input {
  border: none !important;
  background-color: blue;
}
</style> >

